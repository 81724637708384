import { Card } from 'framework7-react';
import { FC } from 'react';
import style from './DriveScoreStyle.module.css';
import img from '@/assets/images/report_img.png';
import { TransitionChart } from '@/components/projects/TransitionChart/TransitionChart';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { TipsCard } from '@/components/uiParts/TipsCard/TipsCard';
import './DriveScoreStyle.scss';

interface DriveScoreProps {
  driveScoreData: any;
  currentDataType: string;
  isLoading: boolean;
  userFamilyName: string;
  handleToggle: (item: string) => void;
  handleClickMonthlyDriveReport: () => void;
}

export const DriveScore: FC<DriveScoreProps> = ({
  driveScoreData,
  currentDataType,
  isLoading,
  userFamilyName,
  handleToggle,
  handleClickMonthlyDriveReport,
}) => {
  const changeBtnsList = [
    { label: '日毎', subLabel: '過去7日間', flag: 'weekScore' },
    { label: '月毎', subLabel: '過去6ヶ月', flag: 'halfYearScore' },
    {
      label: '全期間',
      subLabel: String(new Date().getFullYear() - 3) + '年01月~',
      flag: 'allScore',
    },
  ];
  const reportTitle = {
    title: '月間安全運転レポート',
    subTitle: `${userFamilyName}様の運転傾向の総括です\n定期的に振り返ってみましょう`,
  };

  const imgURL = img;

  const pieces = [
    // A
    {
      gt: 80,
      lte: 100,
      color: '#55a3d2',
    },
    // B
    {
      gt: 60,
      lte: 79,
      color: '#00c4ff',
    },
    // C
    {
      gt: 0,
      lte: 59,
      color: '#ffdc19',
    },
  ];

  const TitleSubtextSelector = () => {
    if (currentDataType === 'weekScore') return '日の平均値';
    else return '月の平均値';
  };

  // 全期間のラベルを1月と7月のみにする
  const createAllFiltered = driveScoreData.scoreAllInfo.createAll?.map(
    (item: string) => {
      const m = parseInt(item.substring(5, 7), 10);
      const y = item.substring(0, 4);
      return m === 1 || m === 7 ? `${m}月\n${y}` : '';
    },
  );

  return (
    <PageTemplate
      pageName="DrivingReport"
      title="安全運転スコア"
      showNavBack={true}
    >
      <div className={style.container}>
        {!isLoading && (
          <RoundSheet>
            <div
              style={{
                width: '100 %',
                height: '24rem',
                position: 'relative',
              }}
            >
              {currentDataType === 'weekScore' && (
                <TransitionChart
                  id="weekScore"
                  title={`安全運転スコア（${TitleSubtextSelector()}）`}
                  yearScoreList={
                    driveScoreData?.scoreWeeklyInfo.weeklyScoreList
                  }
                  createMonthly={driveScoreData?.scoreWeeklyInfo.createWeekly}
                  showLabel={false}
                  showSplitLineX={false}
                  minNumber={0}
                  maxNumber={100}
                  axisLabelUnit="点"
                  gridLeft="14%"
                  seriesType="bar"
                  axisLabelInterval={0}
                  pieces={pieces}
                />
              )}
              {currentDataType === 'halfYearScore' && (
                <TransitionChart
                  id="halfYearScore"
                  title={`安全運転スコア（${TitleSubtextSelector()}）`}
                  yearScoreList={
                    driveScoreData?.scoreMonthlyInfo.monthlyScoreList
                  }
                  createMonthly={driveScoreData?.scoreMonthlyInfo.createMonthly}
                  showLabel={false}
                  showSplitLineX={false}
                  minNumber={0}
                  maxNumber={100}
                  axisLabelUnit="点"
                  gridLeft="14%"
                  seriesType="bar"
                  axisLabelInterval={0}
                  pieces={pieces}
                />
              )}
              {currentDataType === 'allScore' && (
                <TransitionChart
                  id="currentDataType"
                  title={`安全運転スコア（${TitleSubtextSelector()}）`}
                  yearScoreList={driveScoreData?.scoreAllInfo.allScoreList}
                  createMonthly={createAllFiltered}
                  showLabel={false}
                  showSplitLineX={false}
                  minNumber={0}
                  maxNumber={100}
                  axisLabelUnit="点"
                  gridLeft="14%"
                  seriesType="line"
                  axisLabelInterval={0}
                  pieces={pieces}
                />
              )}
            </div>
            <div className="changeBtns u-margin-top-sm u-font-bold">
              {changeBtnsList.map((item, index) => (
                <div
                  className={`${
                    item.flag === currentDataType && 'isActive'
                  } btns-item`}
                  key={index}
                  onClick={() => handleToggle(item.flag)}
                >
                  <div className="u-font-size-lg">{item.label}</div>
                  <div>{item.subLabel}</div>
                </div>
              ))}
            </div>
          </RoundSheet>
        )}
        <TipsCard
          imgUrl={imgURL}
          titles={reportTitle}
          onClick={handleClickMonthlyDriveReport}
        />
        {isLoading && (
          <Card
            className="skeleton-text"
            title="Card Header"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit."
            footer="Card Footer"
          />
        )}
      </div>
    </PageTemplate>
  );
};
