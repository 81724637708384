import { Dispatch, FC, SetStateAction } from 'react';
import { WarrantyPlanRequiredPopup } from '../../TroubleShooting/WarrantyPlanRequiredPopup/WarrantyPlanRequiredPopup';
import { TroubleShootingCardLink } from '../TroubleShootingCardLink/TroubleShootingCardLink';
import style from './MaintenanceGuideStyle.module.css';
import sluggishImage from '@/assets/images/menu/icon_car-blue.svg';
import notStartImage from '@/assets/images/menu/icon_car-yel.svg';
import { Button } from '@/components/uiParts/Button';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';

interface MaintenanceGuideProps {
  handleClickTrouble: () => void;
  handleClickProblem: () => void;
  handleClickContact: () => void;
  setIsOpenWarrantyRequired: Dispatch<SetStateAction<boolean>>;
  nextInspectionDate: string;
  isOpenWarrantyRequired: boolean;
}

export const MaintenanceGuide: FC<MaintenanceGuideProps> = ({
  handleClickProblem,
  handleClickTrouble,
  handleClickContact,
  setIsOpenWarrantyRequired,
  nextInspectionDate,
  isOpenWarrantyRequired,
}) => {
  const acceptableRequests = [
    {
      requests: 'キズ・へこみをなおしたい',
      description: 'お車の板金・修理についてもお問い合わせください。',
    },
    {
      requests: '付属品などの取り付け、購入をしたい',
      description:
        'カーナビ、ドライブレコーダーなどの車載器や各種付属品のご購入、お取り付けのご相談も承ります。​',
    },
  ];

  return (
    <PageTemplate pageName="MaintenanceGuide" title="車両整備" showNavBack>
      <WarrantyPlanRequiredPopup
        isOpen={isOpenWarrantyRequired}
        setIsOpenWarrantyRequired={setIsOpenWarrantyRequired}
      />
      <RoundSheet>
        <h2 className={style.head}>下記のトラブルをお抱えの方は</h2>
        <p className={style.description}>
          状況に応じた対応手順をまとめております。
          <br />
          お困りの際は、ご覧ください。
        </p>
        <div className={style['card-link-container']}>
          <TroubleShootingCardLink
            imageSrc={notStartImage}
            cardTitle="車が動かなくなった！"
            cardBody="パンクやガス欠、キー差込等で車が動かなくなった方はご覧ください"
            accidentType="not-start"
            handleClick={handleClickTrouble}
          />
          <TroubleShootingCardLink
            imageSrc={sluggishImage}
            cardTitle="車が不調な気がする！"
            cardBody="エンジンがかかりにくい、変な音がするなどの不調を感じたときはご覧ください"
            accidentType="sluggish"
            handleClick={handleClickProblem}
          />
        </div>
      </RoundSheet>
      <div style={{ width: '100%', height: '40px' }} />
      <RoundSheet>
        <h2 className={style.head}>こんな依頼を承ります</h2>
        <div className={style['card-container']}>
          <div className={style.card}>
            <h3 className={style['card-head']}>定期点検を依頼したい</h3>
            <p>次回車検予定</p>
            <p>{nextInspectionDate}</p>
          </div>
          {acceptableRequests.map((acceptableRequest, idx) => (
            <div key={idx} className={style.card}>
              <h3 className={style['card-head']}>
                {acceptableRequest.requests}
              </h3>
              <p className={style['card-body']}>
                {acceptableRequest.description}
              </p>
            </div>
          ))}
        </div>
        <p className={style.supplement}>
          上記以外のご依頼も
          <br />
          お気軽にお問合せください
        </p>
        <div className={style['button-wrap']}>
          <Button handleClickAction={handleClickContact} text="お問合せ" />
        </div>
      </RoundSheet>
      <div style={{ width: '100%', height: '40px' }} />
    </PageTemplate>
  );
};
