import { Link, f7 } from 'framework7-react';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { getValidWarranties } from '../../WarrantyManagement/WarrantyManagementHelper';
import { SelectCarForm } from '../SelectCarForm/SelectCarForm';
import style from './contactStyle.module.css';
import contactImage from '@/assets/images/menu/icon-contact.svg';
import openUrlImage from '@/assets/images/menu/icon-open-url.svg';
import phoneImage from '@/assets/images/menu/icon-telephone-2.svg';
import { Card } from '@/components/pages/Contact/Card/Card';
import { ContactCardLinkCardLink } from '@/components/pages/Contact/ContactCardLink/ContactCardLinkCardLink';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { Label } from '@/components/uiParts/Label/Label';
import { NotifyByDate } from '@/components/uiParts/NotifyByDate/NotifyByPeriod';
import { RhfDropdown } from '@/components/uiParts/ReactHookForm/RhfDropdown/RhfDropdown';
import { RhfTextareaWithLabel } from '@/components/uiParts/ReactHookForm/RhfTextareaWithLabel/RhfTextareaWithLabel';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { contacts } from '@/consts/contact';
import { affiliateInquiryFormType } from '@/consts/customer';
import { inquiryType } from '@/consts/inquiry';
import { affiliateId } from '@/consts/shop';
import { InquiryType } from '@/types/inquiry';

export type FormInput = {
  contact: InquiryType;
  detail: string;
  t_stock_car_id?: number;
};

interface ContactProps {
  formMethods: UseFormReturn<FormInput>;
  onSubmit: SubmitHandler<FormInput>;
}

export const Contact: FC<ContactProps> = ({ formMethods, onSubmit }) => {
  const {
    handleSubmit,
    watch,
    formState: { isValid },
  } = formMethods;

  const contact = watch('contact');

  const {
    unit_name,
    unit_tel,
    unit_url,
    affiliate_inquiry_form_type,
    m_affiliate_id,
  } = store.state.customerInfo;
  const { currentWarranty, warranties } = store.state;

  const isAboutRepairWarranty = contact == inquiryType.WARRANTY;

  const carsInfo = store.state.carsInfo;
  const [isVehicleInspection, setIsVehicleInspection] = useState(false);
  const [carSelected, setCarSelected] = useState(0);
  const [validWarranties] = getValidWarranties(warranties);

  const isYoshidaAuto = ([affiliateId.YOSHIDA] as number[]).includes(
    m_affiliate_id,
  );

  const vehicleInspectionPlaceholder = useMemo(() => {
    const baseText = `例：\n車検予約の依頼。\n\n希望日\n①1月1日午前中\n②1月2日13時から16時\n③1月3日11時以降。`;
    if (isYoshidaAuto) {
      return `※本日より3日後以降および火曜日を除きましたご希望の日時をご記載ください。\n\n${baseText}\n\nマッハ車検なかもず店への入庫希望​`;
    }
    return `${baseText}\n\n赤坂工場入庫希望。`;
  }, [isYoshidaAuto]);

  const openAlertWarrantyExpried = () => {
    if (!validWarranties) {
      f7.dialog.alert(
        '現在、ご利用可能な保証はございません。',
        ' エラー',
        () => {},
      );
    }
  };
  const selectCar = (stockCarId: number) => {
    setCarSelected(stockCarId);
  };

  useEffect(() => {
    setIsVehicleInspection(contact == inquiryType.INSPECTION);
  }, [contact]);

  const submit: SubmitHandler<FormInput> = async (data) => {
    onSubmit({
      ...data,
      t_stock_car_id: carSelected,
    });
  };

  const handleOpenUrl = () => {
    if (unit_url) {
      window.open(unit_url, '_blank');
    }
  };
  const handleCallPhone = () => {
    if (unit_tel) {
      window.location.href = 'tel://' + unit_tel.replace(/-/g, '');
    }
  };

  return (
    <PopupPageTemplate id="Contact" pageName="Contact" title="お問い合わせ">
      <div className={style['container']}>
        <NotifyByDate
          displayStartDate="2024/12/25"
          displayEndDate="2025/01/05"
          title="サポートセンター冬季休業につきまして"
          description={`2024年12月28日（土）～2025年1月5日（日）の間、サポートセンター冬季休業とさせていただきます。
休業期間中にいただきましたお問い合わせにつきましては、2025年1月6日（月）より順次ご連絡させていただきます。
ご迷惑をお掛けいたしますが、何卒ご了承いただきますようお願い申し上げます。`}
        />
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(submit)}>
            <div className={style['form-wrapper']}>
              <div>
                <div className={style['label-wrapper']}>
                  <span className={style['label']}>
                    何に関するお問い合せですか？
                  </span>
                  <Label required />
                </div>
                <div className={style['input-check-icon-wrapper']}>
                  <div className={`input-dropdown-wrap ${style['input']}`}>
                    <RhfDropdown
                      required
                      className={style['select']}
                      name="contact"
                    >
                      {contacts.map((item) => (
                        <option key={item.key} value={item.key}>
                          {item.value}
                        </option>
                      ))}
                    </RhfDropdown>
                  </div>
                </div>
              </div>
              {isAboutRepairWarranty && currentWarranty && (
                <Link
                  noLinkClass
                  icon="icon-warranty-repair"
                  text="保証による修理受付はこちら"
                  href={validWarranties ? '/problem/' : false}
                  popupClose={!!validWarranties}
                  routeProps={{ isFromContact: true }}
                  onClick={openAlertWarrantyExpried}
                />
              )}
              {!isVehicleInspection && (
                <>
                  <RhfTextareaWithLabel
                    name="detail"
                    required
                    className={style['textarea']}
                    label="お問合せ内容の詳細をご記載ください"
                    placeholder={`例：保証内容を確認したい
       車の不具合を相談したい`}
                  />
                  <SelectCarForm
                    carsInfo={carsInfo}
                    carSelected={carSelected}
                    selectCar={selectCar}
                  />
                </>
              )}
              {isVehicleInspection &&
                (affiliate_inquiry_form_type ===
                affiliateInquiryFormType.CUSTOMIZE ? (
                  <>
                    <div className={`${style['form-wrapper']} u-margin-top-md`}>
                      <RhfTextareaWithLabel
                        name="detail"
                        required
                        className={style['textarea']}
                        label="お問合せ・問題の詳細をご記載ください"
                        placeholder={vehicleInspectionPlaceholder}
                        style={{ height: 'auto' }}
                        rows={isYoshidaAuto ? 14 : 11}
                      />
                      <SelectCarForm
                        carsInfo={carsInfo}
                        carSelected={carSelected}
                        selectCar={selectCar}
                      />
                      <div className={style['button-container']}>
                        <div className={style['button-wrapper']}>
                          <Button type="submit" disabled={!isValid}>
                            送信する
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <Card title="各店舗へお問合せくださいませ" iconType="">
                    <>
                      <ContactCardLinkCardLink
                        imageLeft={contactImage}
                        imageRight={phoneImage}
                        cardTitle={unit_name}
                        cardBody={unit_tel}
                        cardType="phone"
                        handleClick={handleCallPhone}
                      />
                      {unit_url && (
                        <>
                          <div style={{ width: '100%', height: '16px' }} />
                          <ContactCardLinkCardLink
                            imageLeft=""
                            imageRight={openUrlImage}
                            cardTitle={unit_name}
                            cardBody="ホームページ"
                            cardType="text"
                            handleClick={handleOpenUrl}
                          />
                        </>
                      )}
                    </>
                  </Card>
                ))}
            </div>
            {!isVehicleInspection && (
              <>
                <div className={style['other-info']}>
                  <p className={style['other-info-title']}>
                    販売店へのご用件につきましては、大変恐縮ではございますが、各店舗へご連絡くださいませ。
                  </p>

                  <div className={style['other-info-item']}>
                    <p className={style['other-info-label']}>{unit_name}</p>
                    <span
                      className={style['other-info-content']}
                      onClick={handleCallPhone}
                    >
                      {unit_tel}
                    </span>
                  </div>
                  {unit_url && (
                    <div className={style['other-info-item']}>
                      <p className={style['other-info-label']}>
                        {unit_name} ホームページ
                      </p>
                      <span
                        className={style['other-info-content']}
                        onClick={handleOpenUrl}
                      >
                        {unit_url}
                      </span>
                    </div>
                  )}
                </div>

                <div className={style['button-container']}>
                  <div className={style['button-wrapper']}>
                    <Button type="submit" disabled={!isValid}>
                      送信する
                    </Button>
                  </div>
                </div>
              </>
            )}
          </form>
        </FormProvider>
      </div>
    </PopupPageTemplate>
  );
};
