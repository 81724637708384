import { f7 } from 'framework7-react';
import { FC, useState } from 'react';
import {
  ProblemsType,
  TradeGuaranteeFormInput,
} from '../TradeGuaranteeForm/TradeGuaranteeForm';
import { TRADE_GUARANTEE_STEPS } from '../hooks/useTradeGuarantee';
import style from './Confirmation.module.css';
import { ReactComponent as IconCheckGreen } from '@/assets/images/menu/icon-check-green.svg';
import { Button } from '@/components/uiParts/Button';
import { SuccessDialog } from '@/components/uiParts/SuccessDialog/SuccessDialog';
import { Documents, WarrantyDocument } from '@/types/api/warrantyApi';
import { hyphenToDayNoPadding } from '@/utils/date';

interface ConfirmationProps {
  detailData: TradeGuaranteeFormInput;
  uploadedDocument: Record<number, Documents>;
  warrantyDocument: WarrantyDocument[];
  setStep: (step: number) => void;
  onSubmit: () => Promise<void>;
}

export const Confirmation: FC<ConfirmationProps> = ({
  detailData,
  uploadedDocument,
  warrantyDocument,
  onSubmit,
  setStep,
}) => {
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const uploadedDocs = warrantyDocument.filter((doc) => {
    const upload = uploadedDocument[doc.document_type];
    return upload && upload.files.length > 0;
  });

  const handleSubmit = () => {
    f7.preloader.show();
    onSubmit()
      .then(() => {
        setIsSuccessDialogOpen(true);
      })
      .catch(() => {
        f7.dialog.alert('', 'エラーが発生しました。');
      })
      .finally(() => {
        f7.preloader.hide();
      });
  };

  return (
    <div className={style['container']}>
      <div className={style['section']}>
        <div className={style['section-head']}>
          <h4>取引保証申請内容</h4>
          <span
            className={style['section-head-link']}
            onClick={() => setStep(TRADE_GUARANTEE_STEPS.DETAIL_FORM)}
          >
            編集する
          </span>
        </div>
        <div className={style['section-body']}>
          {detailData.issues.map((issue, index) => (
            <div key={index} className={style['list']}>
              <h4>トラブルの詳細{index + 1}</h4>
              <div className={style['list-item']}>
                <p>トラブルの種類</p>
                <span>
                  {ProblemsType.find(
                    (type) => type.key === issue.issue_category,
                  )?.value || ''}
                </span>
              </div>
              <div className={style['list-item']}>
                <p>トラブル内容</p>
                <span>{issue.issue_detail}</span>
              </div>
              <div
                className={`${style['list-item']} ${
                  index === detailData.issues.length - 1
                    ? style['no-border']
                    : ''
                }`}
              >
                <p>トラブル発生日時</p>
                <span>
                  {hyphenToDayNoPadding(issue.issue_found_date, false)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {uploadedDocs.length > 0 ? (
        <div className={style['section']}>
          <div className={style['section-head']}>
            <h4>各種書類</h4>
            <span
              className={style['section-head-link']}
              onClick={() => setStep(TRADE_GUARANTEE_STEPS.UPLOAD_DOCUMENTS)}
            >
              編集する
            </span>
          </div>
          <div className={style['section-body']}>
            <div className={style['check-list-container']}>
              {uploadedDocs.map((doc, index) => (
                <div className={style['check-list']} key={index}>
                  <IconCheckGreen width={24} height={24} />
                  {doc.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : <></>}
      <div className={style['footer-button']}>
        <Button onClick={handleSubmit}>この内容で申請</Button>
      </div>
      <SuccessDialog
        title="取引保証を申請しました"
        open={isSuccessDialogOpen}
        onClose={() => {
          setIsSuccessDialogOpen(false);
          setTimeout(() => {
            f7.view.main.router.back();
          });
        }}
      />
    </div>
  );
};
