const shops = [
  'default',
  'kusunaga',
  'carminiku',
  'yoshida',
  'sanwa',
  'carinc',
  'jnet',
  'flex',
  'royal',
  'carchs',
  'proud',
  'tsuchiya',
  'action1',
  'yoshida_coltd',
  'gtnet',
  'liberala',
  'kind_outlet',
  'yamada',
  'sunaiku',
] as const;

export const prepaidName = {
  DEFAULT: 'マイくるPay',
  USAGI_PREPAID: 'うさぎプリペイド',
} as const;

export const themeId: { [key in Uppercase<Shop>]: number } = {
  DEFAULT: 1,
  KUSUNAGA: 2,
  CARMINIKU: 3,
  YOSHIDA: 4,
  SANWA: 5,
  CARINC: 6,
  JNET: 7,
  FLEX: 8,
  ROYAL: 9,
  CARCHS: 10,
  PROUD: 11,
  TSUCHIYA: 12,
  ACTION1: 13,
  YOSHIDA_COLTD: 14,
  GTNET: 15,
  LIBERALA: 16,
  KIND_OUTLET: 17,
  YAMADA: 18,
  SUNAIKU: 19,
} as const;

export const affiliateId = {
  DEFAULT: 1,
  KUSUNAGA: 85,
  CARMINIKU: 87,
  YOSHIDA: 84,
  SANWA: 81,
  CARINC: 82,
  JNET: 140,
  FLEX: 97,
  ROYAL: 268,
  CARCHS: 303,
  PROUD: 115,
  TSUCHIYA: 2,
  ACTION1: 328,
  YOSHIDA_COLTD: 124,
  GTNET: 101,
  LIBERALA: 102,
  KIND_OUTLET: 110,
  YAMADA: 331,
  SUNAIKU: 433,
} as const;

export type Shop = typeof shops[number];
export type ThemeId = typeof themeId[keyof typeof themeId];
export type AffiliateId = typeof affiliateId[keyof typeof affiliateId];
type ShopThemeObj = {
  mainColor: string;
  mainBgColor: string;
  // homeWrapperBg: string;
  homeWrapperSubBg: string;
  homeWrapperSubBgPc: string;
  homeBg: string;
  homeBorderColorFirst: string;
  homeBorderColorSecond: string;
  prepaidColor: {
    main: string;
    leftTop?: string;
    rightBottom?: string;
    boxShadow?: string;
  };
};
type ShopTheme = { [key in Shop]: ShopThemeObj };

export const shopTheme: ShopTheme = {
  default: {
    mainColor: '#323232',
    mainBgColor: '#efefef',
    // homeWrapperBg: 'stripe_head.svg',
    homeWrapperSubBg: 'stripe_bg.svg',
    homeWrapperSubBgPc: 'stripe_bg_pc.svg',
    homeBg: 'home-bg.svg',
    homeBorderColorFirst: '#FF4655',
    homeBorderColorSecond: '#00DCFA',
    prepaidColor: {
      main: '#171a1c',
      leftTop: '#767676',
      rightBottom: '#000',
    },
  },
  kusunaga: {
    mainColor: '#464646',
    mainBgColor: '#fff3e5',
    // homeWrapperBg: 'stripe_head_kusunaga.svg',
    homeWrapperSubBg: 'stripe_bg_kusunaga.svg',
    homeWrapperSubBgPc: 'stripe_bg_kusunaga_pc.svg',
    homeBg: 'home-bg-kusunaga.svg',
    homeBorderColorFirst: '#C66300',
    homeBorderColorSecond: '#F3B466',
    prepaidColor: {
      main: '#eb8200',
      leftTop: '#f0a13e',
      rightBottom: '#eb8200',
    },
  },
  carminiku: {
    mainColor: '#2f2f2f',
    mainBgColor: '#e5ede1',
    // homeWrapperBg: 'stripe_head_kaminiku.svg',
    homeWrapperSubBg: 'stripe_bg_kaminiku.svg',
    homeWrapperSubBgPc: 'stripe_bg_kaminiku_pc.svg',
    homeBg: 'home-bg-kaminiku.svg',
    homeBorderColorFirst: '#BE552E',
    homeBorderColorSecond: '#55E072',
    prepaidColor: {
      main: '#2da546',
      leftTop: '#85c790',
      rightBottom: '#2da546',
    },
  },
  yoshida: {
    mainColor: '#3e3e3e',
    mainBgColor: '#f8ebd6',
    // homeWrapperBg: 'stripe_head_yoshida.svg',
    homeWrapperSubBg: 'stripe_bg_yoshida.svg',
    homeWrapperSubBgPc: 'stripe_bg_yoshida_pc.svg',
    homeBg: 'home-bg-yoshida.svg',
    homeBorderColorFirst: '#A72E11',
    homeBorderColorSecond: '#F86356',
    prepaidColor: {
      main: '#e95411',
      leftTop: '#ee7c48',
      rightBottom: '#e95411',
    },
  },
  sanwa: {
    mainColor: '#252525',
    mainBgColor: '#e7f5fd',
    // homeWrapperBg: 'stripe_head_sanwa.svg',
    homeWrapperSubBg: 'stripe_bg_sanwa.svg',
    homeWrapperSubBgPc: 'stripe_bg_sanwa_pc.svg',
    homeBg: 'home-bg-sanwa.svg',
    homeBorderColorFirst: '#0851D0',
    homeBorderColorSecond: '#00A0E9',
    prepaidColor: {
      main: '#0057aa',
      leftTop: '#7fabd4',
      rightBottom: '#0057aa',
    },
  },
  carinc: {
    mainColor: '#000002',
    mainBgColor: '#efefef',
    // homeWrapperBg: 'stripe_head_kainku.svg',
    homeWrapperSubBg: 'stripe_bg_kainku.svg',
    homeWrapperSubBgPc: 'stripe_bg_kainku_pc.svg',
    homeBg: 'home-bg-kainku.svg',
    homeBorderColorFirst: '#D60B1B',
    homeBorderColorSecond: '#868686',
    prepaidColor: {
      main: '#d60b1b',
      leftTop: '#dd7880',
      rightBottom: '#d60b1b',
    },
  },
  jnet: {
    mainColor: '#231916',
    mainBgColor: '#eeeeee',
    // homeWrapperBg: 'stripe_head_jnet.svg',
    homeWrapperSubBg: 'stripe_bg_jnet.svg',
    homeWrapperSubBgPc: 'stripe_bg_jnet_pc.svg',
    homeBg: 'home-bg-jnet.svg',
    homeBorderColorFirst: '#930105',
    homeBorderColorSecond: '#E50013',
    prepaidColor: {
      main: '#C70505',
      leftTop: '#dd7880',
      rightBottom: '#C70505',
    },
  },
  flex: {
    mainColor: '#19191A',
    mainBgColor: '#EBEDF0',
    // homeWrapperBg: 'stripe_head_flex.svg',
    homeWrapperSubBg: 'stripe_bg_flex.svg',
    homeWrapperSubBgPc: 'stripe_bg_flex_pc.svg',
    homeBg: 'home-bg-flex.svg',
    homeBorderColorFirst: '#0255B8',
    homeBorderColorSecond: '#F3E500',
    prepaidColor: {
      main: '#0256BA',
      leftTop: '#FFFFFF',
      rightBottom: '#FDFDFD',
    },
  },
  royal: {
    mainColor: '#333333',
    mainBgColor: '#EEF0F2',
    // homeWrapperBg: 'stripe_head_royal.svg',
    homeWrapperSubBg: 'stripe_bg_royal.svg',
    homeWrapperSubBgPc: 'stripe_bg_royal_pc.svg',
    homeBg: 'home-bg-royal.svg',
    homeBorderColorFirst: '#3875D4',
    homeBorderColorSecond: '#9CC1F8',
    prepaidColor: {
      main: '#093880',
      leftTop: '#FFFFFF',
      rightBottom: '#FDFDFD',
    },
  },
  carchs: {
    mainColor: '#242424',
    mainBgColor: '#F2F2F2',
    // homeWrapperBg: 'stripe_head_royal.svg',
    homeWrapperSubBg: 'stripe_bg_carchs.svg',
    homeWrapperSubBgPc: 'stripe_bg_carchs_pc.svg',
    homeBg: 'home-bg.svg',
    homeBorderColorFirst: '#E7261D',
    homeBorderColorSecond: '#FCD8D7',
    prepaidColor: {
      main: '#D4261E',
      leftTop: '#FFFFFF',
      rightBottom: '#FDFDFD',
    },
  },
  proud: {
    mainColor: '#1A1A1A',
    mainBgColor: '#EEF0F2',
    // homeWrapperBg: 'stripe_head_proud.svg',
    homeWrapperSubBg: 'stripe_bg_proud.svg',
    homeWrapperSubBgPc: 'stripe_bg_proud_pc.svg',
    homeBg: 'home-bg-royal.svg',
    homeBorderColorFirst: '#073E73',
    homeBorderColorSecond: '#E60039',
    prepaidColor: {
      main: '#E60039',
      leftTop: '#FFFFFF',
      rightBottom: '#FDFDFD',
    },
  },
  tsuchiya: {
    mainColor: '#3A2313',
    mainBgColor: '#F5F3E4',
    // homeWrapperBg: 'stripe_head_proud.svg',
    homeWrapperSubBg: 'stripe_bg_tsuchiya.svg',
    homeWrapperSubBgPc: 'stripe_bg_tsuchiya_pc.svg',
    homeBg: 'home-bg.svg',
    homeBorderColorFirst: '#CC2B53',
    homeBorderColorSecond: '#EFCBD9',
    prepaidColor: {
      main: '#9EBE42',
      leftTop: '#FFFFFF',
      rightBottom: '#FDFDFD',
    },
  },
  action1: {
    mainColor: '#191919',
    mainBgColor: '#EDEDED',
    // homeWrapperBg: 'stripe_head_proud.svg',
    homeWrapperSubBg: 'stripe_bg_action1.svg',
    homeWrapperSubBgPc: 'stripe_bg_action1_pc.svg',
    homeBg: 'home-bg.svg',
    homeBorderColorFirst: '#FE2626',
    homeBorderColorSecond: '#C4C4C4',
    prepaidColor: {
      main: '#9EBE42',
      leftTop: '#FFFFFF',
      rightBottom: '#FDFDFD',
    },
  },
  yoshida_coltd: {
    mainColor: '#191919',
    mainBgColor: '#EDEDED',
    // homeWrapperBg: 'stripe_head_proud.svg',
    homeWrapperSubBg: 'stripe_bg_yoshida_coltd.svg',
    homeWrapperSubBgPc: 'stripe_bg_yoshida_coltd_pc.svg',
    homeBg: 'home-bg.svg',
    homeBorderColorFirst: '#D91515',
    homeBorderColorSecond: '#EDED26',
    prepaidColor: {
      main: '#EDED26',
      leftTop: '#FFFFFF',
      rightBottom: '#FDFDFD',
    },
  },
  gtnet: {
    mainColor: '#191919',
    mainBgColor: '#EDEDED',
    homeWrapperSubBg: 'stripe_bg_gtnet.svg',
    homeWrapperSubBgPc: 'stripe_bg_gtnet_pc.svg',
    homeBg: 'home-bg.svg',
    homeBorderColorFirst: '#D0121B',
    homeBorderColorSecond: '#BABABA',
    prepaidColor: {
      main: '#D0121B',
      leftTop: '#fa5f66',
      rightBottom: '#D0121B',
    },
  },
  liberala: {
    mainColor: '#242424',
    mainBgColor: '#F0F0F0',
    homeWrapperSubBg: 'stripe_bg_liberala.svg',
    homeWrapperSubBgPc: 'stripe_bg_liberala_pc.svg',
    homeBg: 'home-bg.svg',
    homeBorderColorFirst: '#878787',
    homeBorderColorSecond: '#C4C4C4',
    prepaidColor: {
      main: '#242424',
      leftTop: '#C4C4C4',
      rightBottom: '#171717',
    },
  },
  kind_outlet: {
    mainColor: '#2C2525',
    mainBgColor: '#F7EFEF',
    homeWrapperSubBg: 'stripe_bg_kind_outlet.svg',
    homeWrapperSubBgPc: 'stripe_bg_kind_outlet_pc.svg',
    homeBg: 'home-bg.svg',
    homeBorderColorFirst: '#BA1A1A',
    homeBorderColorSecond: '#F77F7F',
    prepaidColor: {
      main: '#EDED26',
      leftTop: '#FFFFFF',
      rightBottom: '#FDFDFD',
    },
  },
  yamada: {
    mainColor: '#323232',
    mainBgColor: '#efefef',
    // homeWrapperBg: 'stripe_head.svg',
    homeWrapperSubBg: 'stripe_bg_yamada.svg',
    homeWrapperSubBgPc: 'stripe_bg_yamada_pc.svg',
    homeBg: 'home-bg.svg',
    homeBorderColorFirst: '#004EA2',
    homeBorderColorSecond: '#E60012',
    prepaidColor: {
      main: '#EDED26',
      leftTop: '#FFFFFF',
      rightBottom: '#FDFDFD',
    },
  },
  sunaiku: {
    mainColor: '#2C2525',
    mainBgColor: '#F6F0EC',
    homeWrapperSubBg: 'stripe_bg_sunaiku.svg',
    homeWrapperSubBgPc: 'stripe_bg_sunaiku_pc.svg',
    homeBg: 'home-bg.svg',
    homeBorderColorFirst: '#EC6B18',
    homeBorderColorSecond: '#E7141A',
    prepaidColor: {
      main: '#EC6B18',
      leftTop: '#FFFFFF',
      rightBottom: '#FDFDFD',
    },
  },
};
