import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useLinkDriveOwner } from './api/info/useLinkDriveOwner';
import { useConnectorJob } from './api/linkDrive/useConnectorJob';
import { useConnectorStatus } from './api/linkDrive/useConnectorStatus';
import { useLinkDriveSetupStatus } from './api/linkDrive/useLinkDriveSetupStatus';
import { jobExecStatus, jobStatusApi } from '@/api/linkDriveApi';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import {
  calibrationSteps,
  connectorJob,
  connectorStatusApiError,
  eventStatusLaunch,
  jobTerminateStatus,
  linkDriveJobExecStatus,
  mode,
} from '@/consts/linkDrive';
import {
  ConnectorJobApiParams,
  ConnectorStatusApiParams,
  JobExecStatusApiResponse,
  LinkDriveSetupStatusApiParams,
} from '@/types/api/linkDriveApi';
import { storeDispatch } from '@/utils/store';
import { f7CustomBack } from '@/utils/utils';

interface StepsType {
  step1: string;
  step2: string;
  step3: string;
  step4: string;
}

const timeoutBlock = (timout?: number) =>
  new Promise<boolean>((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, timout || 3000);
  });

export const useLinkDriveStep = (
  f7router: Router.Router,
  setIsCalibrationCompleted: Dispatch<SetStateAction<boolean>>,
) => {
  const ownerId = store.state.linkDriveOwnerInfo.owner_id;
  const serialNo = store.state.linkDriveOwnerInfo.serial_no;
  const { t_stock_car_id } = store.state.carItemInfo;
  const { m_customer_id } = store.state.authInfo;
  const requestConnectorJobParams: ConnectorJobApiParams = {
    owner_id: Number(ownerId),
    job: '11',
    mode: '',
  };
  const fetchConnectorStatusParams: ConnectorStatusApiParams = {
    owner_id: Number(ownerId),
    serial_no: serialNo,
  };
  const linkDriveSetupStatusParams: LinkDriveSetupStatusApiParams = {
    owner_id: Number(ownerId),
    setUpMode: 2,
  };
  const linkDriveOwnerParams = {
    params: {
      customer_id: m_customer_id,
      stock_car_id: t_stock_car_id,
      del_flg: 0,
    },
  };

  // hooks定義
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(calibrationSteps.ENGINE_START);
  const [controller, setController] = useState(new AbortController());
  const [showDialog, setShowDialog] = useState(false);
  const [showStandByDialog, setShowStandByDialog] = useState(false);
  const [closeSkip, setCloseSkip] = useState(false);
  const { requestConnectorJob } = useConnectorJob();
  const { setStoreLinkDriveOwner } = useLinkDriveOwner(linkDriveOwnerParams);
  const { fetchConnectorStatus } = useConnectorStatus();
  const { fetchLinkDriveSetupStatus } = useLinkDriveSetupStatus();
  // const { connectorStatusRecursiveCall } = useConnectorStatusRecursiveCall();

  /*************************************
   *  エンジン始動処理
   *************************************/
  // 手順1 → 手順2
  const handleClickStartedEngine = () => {
    setCurrentStep(calibrationSteps.IN_PROGRESS);

    // コネクタ状態取得APIを80秒間コールさせるため27回に設定
    // 200回 × 3秒 = 600秒
    connectorStatusRecursiveCall(200);
  };

  const initializeCalibrationProcess = () => {
    setCurrentStep(calibrationSteps.ENGINE_START);
  };

  const changeToProgressError = () => {
    setCurrentStep(calibrationSteps.PROGRESS_ERROR);
  };

  /*************************************
   *  走行開始処理
   *************************************/
  // 手順2 → 手順3

  const handleClickSetupCalibration = () => {
    if (store.state.calibrationJobId) {
      const timeout = setTimeout(() => {
        setCloseSkip(true);
        setShowStandByDialog(true);
      }, 10000);
      handleCheckJobStatus(store.state.calibrationJobId, timeout).catch(
        (e: any) => {
          clearTimeout(timeout);
          storeDispatch('setCalibrationJobId', 'calibrationJobId', undefined);
          f7.dialog.alert(
            e.message || 'コネクタが起動されてるかを確認してください',
            'エラー',
            changeToProgressError,
          );
        },
      );
      setCurrentStep(calibrationSteps.IN_PROGRESS);
      return;
    }
    setCurrentStep(calibrationSteps.CHECK_POSITION);
  };

  const handleClickCheckHorizontal = () => {
    setCurrentStep(calibrationSteps.IN_PREPARATION);
  };

  const handleClickBtnFinish = () => {
    f7.view.main.router.navigate(paths.connectorSetting, { animate: true });
  };

  // 手順3 → 手順4
  const changeStepsToCompleted = () => {
    setStoreLinkDriveOwner({ params: linkDriveOwnerParams });
    setCurrentStep(calibrationSteps.COMPLETED);
  };

  // トラブル発生時
  const handleClickCalibrationTrouble = () => {
    setCurrentStep(calibrationSteps.IN_PREPARATION);
  };

  // 走行再開
  const handleClickDriveRestart = () => {
    setCurrentStep(calibrationSteps.IN_PREPARATION);
  };

  const handleClickCalibrationCompleted = () => {
    setIsCalibrationCompleted(true);
    f7.view.main.router.navigate(paths.connectorSetting, { animate: true });
  };

  // キャリブレーション中止
  const handleClickCalibrationStop = () => {
    controller.abort();
    f7CustomBack();
  };

  const handleClickStatusCheckError = () => {
    controller.abort();
    f7CustomBack();
  };

  const handleClose = () => {
    controller.abort();
    f7CustomBack();
  };

  const handleClickClose = () => {
    if (
      [calibrationSteps.ENGINE_START, calibrationSteps.COMPLETED].includes(
        currentStep,
      ) ||
      closeSkip
    ) {
      controller.abort();
      f7CustomBack();
      return;
    }
    setShowDialog(true);
  };

  const handleCheckJobStatus = (job_id: string, timeout?: NodeJS.Timeout) => {
    return new Promise<boolean>((resolve, reject) => {
      jobStatusApi(
        {
          owner_id: ownerId,
          job: connectorJob.CALIBRATION,
          job_id,
        },
        controller.signal,
      ).then((rs) => {
        if (!rs || !rs.data) {
          resolve(true);
          return;
        }
        const { data } = rs;
        if (data.success) {
          const { job_status, status, progress } = rs.data.data;
          setProgress(progress || 10);
          if (job_status === linkDriveJobExecStatus.ABNORMAL_TERMINATION) {
            storeDispatch('setCalibrationJobId', 'calibrationJobId', undefined);
            clearTimeout(timeout);
            if (status === jobTerminateStatus.NOT_CONNECTED) {
              setCurrentStep(calibrationSteps.STATUS_CHECK_ERROR);
            } else {
              changeToProgressError();
            }
            return;
          }
          if (
            job_status === linkDriveJobExecStatus.SUCCESSFUL_END ||
            progress >= 100
          ) {
            storeDispatch('setCalibrationJobId', 'calibrationJobId', undefined);
            clearTimeout(timeout);
            changeStepsToCompleted();
            return;
          }
          timeoutBlock()
            .then(() => handleCheckJobStatus(job_id, timeout))
            .then((result) => resolve(result))
            .catch((e) => reject(e));
        } else {
          reject({ message: rs.data.error?.message });
        }
      });
    });
  };

  const handleCalibrationCheckRs = (
    result: JobExecStatusApiResponse,
    job_id?: string,
    timeout?: NodeJS.Timeout,
  ) => {
    return new Promise<boolean>((resolve, reject) => {
      const { data } = result;
      if (data && data.success) {
        const { job, job_status } = data.data;
        if (
          ![
            linkDriveJobExecStatus.SUCCESSFUL_END,
            linkDriveJobExecStatus.INTERUPTED,
            linkDriveJobExecStatus.ABNORMAL_TERMINATION,
          ].includes(job_status) &&
          Number(job) === Number(connectorJob.CALIBRATION) &&
          job_id
        ) {
          handleCheckJobStatus(job_id, timeout)
            .then((rs) => resolve(rs))
            .catch((e: any) => {
              reject(e);
            });
        } else {
          resolve(true);
        }
      } else {
        reject({ message: data.error_message });
      }
    });
  };

  const requestCalibrationJob = () => {
    requestConnectorJob({
      params: requestConnectorJobParams,
      resolve: (rs) => {
        const timeout = setTimeout(() => {
          setCloseSkip(true);
          setShowStandByDialog(true);
        }, 10000);
        jobExecStatus({
          owner_id: ownerId,
        })
          .then((jobExecRs) => {
            storeDispatch('setCalibrationJobId', 'calibrationJobId', rs.id);
            return handleCalibrationCheckRs(jobExecRs, rs.id, timeout);
          })
          .catch((e: any) => {
            clearTimeout(timeout);
            storeDispatch('setCalibrationJobId', 'calibrationJobId', undefined);
            f7.dialog.alert(
              e.message || 'コネクタが起動されてるかを確認してください',
              'エラー',
              changeToProgressError,
            );
          });
      },
      onFailure: () => {
        initializeCalibrationProcess();
      },
    });
  };

  const checkJobStatus = () => {
    jobExecStatus({
      owner_id: ownerId,
    })
      .then((jobExecRs) => {
        const { data } = jobExecRs;
        if (data && data.success) {
          const { job, job_status, job_id } = data.data;
          if (
            [
              linkDriveJobExecStatus.SUCCESSFUL_END,
              linkDriveJobExecStatus.INTERUPTED,
              linkDriveJobExecStatus.ABNORMAL_TERMINATION,
            ].includes(job_status) &&
            job_id
          ) {
            const timeout = setTimeout(() => {
              setCloseSkip(true);
              setShowStandByDialog(true);
            }, 10000);
            storeDispatch('setCalibrationJobId', 'calibrationJobId', job_id);
            handleCalibrationCheckRs(jobExecRs, job_id, timeout).catch(
              (e: any) => {
                clearTimeout(timeout);
                storeDispatch(
                  'setCalibrationJobId',
                  'calibrationJobId',
                  undefined,
                );
                f7.dialog.alert(
                  e.message || 'コネクタが起動されてるかを確認してください',
                  'エラー',
                  changeToProgressError,
                );
              },
            );
          } else {
            requestCalibrationJob();
          }
          return;
        }
        throw new Error(data.error_message || '');
      })
      .catch((e: any) => {
        f7.dialog.alert(
          e.message || 'コネクタが起動されてるかを確認してください',
          'エラー',
          initializeCalibrationProcess,
        );
      });
  };

  // TODO: useConnectorStatusRecursiveCallを使う
  const connectorStatusRecursiveCall = (remainingCallCount: number) => {
    // ベースケース
    // コネクタ状態取得APIが20回呼ばれたらダイアログ表示
    if (remainingCallCount <= 0) {
      // f7.dialog.alert(
      //   'コネクタが起動されてるかを確認してください',
      //   'エラー',
      //   initializeCalibrationProcess,
      // );
      requestCalibrationJob();
      return;
    }
    setTimeout(() => {
      fetchConnectorStatus({
        params: fetchConnectorStatusParams,
        resolve: (data) => {
          //  APIコール回数を減らしてコネクタ状態取得APIをコール
          if (!data.success) {
            if (
              data.error?.code === 'DATA_NOT_AVAILABLE' ||
              // codeのみの条件分岐が望ましい
              // 現在はvue版に合わせるためmessageも条件に入れている
              data.error?.message === connectorStatusApiError.NO_STATUS_DATA
            ) {
              connectorStatusRecursiveCall(remainingCallCount - 1);
              return;
            }
            f7.dialog.alert(
              data.error?.message || 'エラー発生しています',
              'エラー',
              initializeCalibrationProcess,
            );
            return;
          } else {
            if (data.data.event === mode.launch) {
              if (
                data.data.event_status === eventStatusLaunch.COMPLETE_STANDBY
              ) {
                requestCalibrationJob();
                return;
              }

              if (data.data.event_status === eventStatusLaunch.REFRESH_REBOOT) {
                checkJobStatus();
                return;
              }
            }
          }
          connectorStatusRecursiveCall(remainingCallCount - 1);
        },
      });
    }, 3000);
  };

  return {
    currentStep,
    showDialog,
    progress,
    showStandByDialog,
    handleClickStartedEngine,
    handleClickCalibrationTrouble,
    handleClickStatusCheckError,
    handleClickCalibrationCompleted,
    handleClickDriveRestart,
    handleClickCalibrationStop,
    setShowDialog,
    setShowStandByDialog,
    handleClose,
    handleClickClose,
    handleClickSetupCalibration,
    handleClickCheckHorizontal,
    handleClickBtnFinish,
  };
};
