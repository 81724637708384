import { Popup } from 'framework7-react';
import { FC } from 'react';
import style from './SuccessDialogStyle.module.css';

interface SuccessDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  content?: string;
}

export const SuccessDialog: FC<SuccessDialogProps> = ({
  open,
  onClose,
  title,
  content,
}) => {
  return (
    <Popup
      opened={open}
      onPopupClosed={onClose}
      closeByBackdropClick
      animate={false}
      className={style['popup']}
    >
      <div className={style['container']}>
        <div className={style['close-icon']}>
          <i className="icon-close" onClick={onClose} />
        </div>
        <div className={style['success-icon']}>
          <i className="icon-success" />
        </div>
        <div className={style['title']}>
          {title || '書類アップロードが完了し'} <br />
          {title || '保証が有効化されました​'}
        </div>
        <p className={style['content']}>
          書類不備があった場合は審査完了次第ご連絡いたします。
        </p>
      </div>
    </Popup>
  );
};
