import { baseApi, baseFormDataApi } from '@/config/axios';
import {
  CancelWarrantyParams,
  CancelWarrantyResponse,
  DealWarrantyRefundAccountApiParams,
  DealWarrantyRefundAccountApiResponse,
  GetDealWarrantyInfoApiParams,
  GetDealWarrantyInfoApiResponse,
  GetGuaranteeUpdateTargetParams,
  GetGuaranteeUpdateTargetResponse,
  GetUploadedWarrantyDocumentsParams,
  GetUploadedWarrantyDocumentsResponse,
  GetWarrantyDocumentsParams,
  GetWarrantyDocumentsResponse,
  PaymentMethodChangeParams,
  PaymentMethodChangeResponse,
  PostDealWarrantyApiParams,
  PostDealWarrantyApiResponse,
  PostDealWarrantyUpdateApiParams,
  PostDealWarrantyUpdateApiResponse,
  PostWarrantyDocumentsParams,
  PostWarrantyDocumentsResponse,
  PutGuaranteeUpdateTargetParams,
  PutGuaranteeUpdateTargetResponse,
  StopUpdateNextYearParams,
  StopUpdateNextYearResponse,
  UpdateWarrantyCustomerParams,
  UpdateWarrantyCustomerResponse,
} from '@/types/api/warrantyApi';

export const cancelWarryantyApi = (
  params: CancelWarrantyParams,
): Promise<CancelWarrantyResponse> =>
  baseApi.put('/v1/customer/stop-update-next-year', { params });

export const updateWarrantyCustomerApi = (
  params: UpdateWarrantyCustomerParams,
): Promise<UpdateWarrantyCustomerResponse> =>
  baseApi.put('/v1/customer', { params });

export const getGuaranteeUpdateTargetApi = (
  params: GetGuaranteeUpdateTargetParams,
): Promise<GetGuaranteeUpdateTargetResponse> =>
  baseApi.get('/v1/customer/guarantee-update-target', { params });

//決済方法変更
export const paymentMethodChangeApi = (
  params: PaymentMethodChangeParams,
): Promise<PaymentMethodChangeResponse> =>
  baseApi.put('v1/customer/payment_method_change', params);

export const stopUpdateNextYear = (
  params: StopUpdateNextYearParams,
): Promise<StopUpdateNextYearResponse> =>
  baseApi.put('/v1/customer/stop-update-next-year', params);

export const putGuaranteeUpdateTargetApi = (
  params: PutGuaranteeUpdateTargetParams,
): Promise<PutGuaranteeUpdateTargetResponse> =>
  baseApi.put('/v1/customer/guarantee-update-target', params);

export const postDealWarrantyApi = (
  params: PostDealWarrantyApiParams,
): Promise<PostDealWarrantyApiResponse> => {
  const formData = new FormData();
  formData.append('warranty_no', String(params.warranty_no));
  formData.append('m_customer_id', String(params.m_customer_id));
  formData.append('m_affiliate_id', String(params.m_affiliate_id));
  formData.append('m_unit_id', String(params.m_unit_id));
  formData.append('t_stock_car_id', String(params.t_stock_car_id));
  formData.append('branch_no', String(params.branch_no));
  formData.append('uploaded_by', String(params.uploaded_by));
  params.issues.forEach((issue, index) => {
    formData.append(
      `issues[${index}][issue_category]`,
      String(issue.issue_category),
    );
    formData.append(
      `issues[${index}][issue_detail]`,
      String(issue.issue_detail),
    );
    formData.append(
      `issues[${index}][issue_found_date]`,
      String(issue.issue_found_date),
    );
  });
  // params.documents.forEach((document, index) => {
  //   formData.append(
  //     `documents[${index}][m_warranty_document_property_id]`,
  //     String(document.m_warranty_document_property_id),
  //   );
  //   formData.append(`documents[${index}][file]`, document.file);
  // });

  return baseFormDataApi.post('/v1/customer/deal-warranty', params);
};

export const getDealWarrantyInfoApi = (
  params: GetDealWarrantyInfoApiParams,
): Promise<GetDealWarrantyInfoApiResponse> =>
  baseApi.get('/v1/customer/deal-warranty/info', { params });

export const dealWarrantyRefundAccountApi = (
  params: DealWarrantyRefundAccountApiParams,
): Promise<DealWarrantyRefundAccountApiResponse> =>
  baseApi.post('/v1/customer/deal-warranty/refund-account', params);

export const postDealWarrantyUpdateApi = (
  params: PostDealWarrantyUpdateApiParams,
): Promise<PostDealWarrantyUpdateApiResponse> =>
  baseApi.post('/v1/customer/deal-warranty/update', params);

export const getWarrantyDocumentsApi = (
  params: GetWarrantyDocumentsParams,
): Promise<GetWarrantyDocumentsResponse> =>
  baseApi.get('/v1/customer/warranty-documents', { params });

export const postWarrantyDocumentsApi = (
  params: PostWarrantyDocumentsParams,
): Promise<PostWarrantyDocumentsResponse> => {
  const formData = new FormData();
  formData.append('warranty_no', String(params.warranty_no));
  formData.append('uploaded_by', String(params.uploaded_by));
  const index_start = params.index_start ? params.index_start : 0;
  params.documents.forEach((document, index) => {
    formData.append(
      `documents[${index + index_start}][document_type]`,
      String(document.document_type),
    );
    formData.append(`documents[${index + index_start}][file]`, document.file);
  });

  return baseFormDataApi.post('v1/customer/warranty-documents', formData);
};

export const getUploadedWarrantyDocumentsApi = (
  params: GetUploadedWarrantyDocumentsParams,
): Promise<GetUploadedWarrantyDocumentsResponse> =>
  baseApi.get('/v1/customer/warranty-documents/uploaded-files', { params });
